import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["img"]

  connect() {
    if (!this.hasImgTarget) return

    this.loadCount = 0
    this.maxTries = 6
    this.src = this.imgTarget.src

    if (!this._isImageLoaded()) this.element.classList.add("is-loading")
  }

  loaded() {
    this.element.classList.remove("is-loading")
  }

  error(e) {
    this.loadCount += 1

    if (this.loadCount < this.maxTries) {
      this._updateSrc(`${this.src}#${this.loadCount}`)
    } else if (this.loadCount == this.maxTries) {
      this._updateSrc(this.element.dataset.paintingMissingUrlValue)
    }
  }

  _isImageLoaded() {
    // https://stackoverflow.com/a/1977898/311657
    return this.imgTarget.complete && this.imgTarget.naturalWidth
  }

  _updateSrc(src) {
    const sources = Array.from(this.element.querySelectorAll('source'))
    sources.forEach(source => source.remove())
    this.imgTarget.src = src
  }
}
