import { Controller } from "stimulus"

const rounded = (value) => {
  const rounded = Math.round(value * 100) / 100
  return isNaN(rounded) ? "?" : rounded
}

const percentage = (price, percent) => {
  return price - price * percent / 100
}

// Calculate the price of a creation
//
// Example:
//     <div
//       data-controller="price-calculator"
//       data-price-calculator-commission-rate="0.1"
//     >
//       Price:
//       <input
//         data-price-calculator-target="price"
//         data-action="change->price-calculator#update"
//       /> €
//       <input
//         data-price-calculator-target="promotion"
//         data-action="change->price-calculator#update"
//       /> %
//       Price: <span data-price-calculator-target="publicPrice"></span> €
//       Commission: <span data-price-calculator-target="commission"></span> €
//       Revenue: <span data-price-calculator-target="revenue"></span> €
//     />
export default class extends Controller {
  static targets = [
    "price",
    "promotionPercentage",
    "promotionPercentageMax",
    "promotionResult",
    "noPromotionResult",
    "priceText",
    "publicPrice",
    "commission",
    "revenue"
  ]

  update() {
    this._updatePromotionPercentage()
    this._updateCommission()
  }

  _updatePromotionPercentage() {
    if (!this.hasPromotionPercentageTarget) return

    const promotion = this.promotionPercentageTarget

    // Update maximum
    const maxPromotionPercentage = this._maxPromotionPercentage()
    promotion.max = maxPromotionPercentage
    this.promotionPercentageMaxTarget.innerText = maxPromotionPercentage

    // Lower promotion if it goes over the maximum
    const promotionPercentage = parseFloat(promotion.value)
    if (promotionPercentage > maxPromotionPercentage) {
      promotion.value = maxPromotionPercentage
    }

    const hasPromotion = promotion.value != 0
    this.noPromotionResultTarget.style.display =
      hasPromotion ? "none" : "initial"
    this.promotionResultTarget.style.display = hasPromotion ? "initial" : "none"

    this.publicPriceTarget.innerText = rounded(this._publicPrice())
  }

  _updateCommission() {
    const price = this._price()
    const publicPrice = this._publicPrice()
    const commissionRate = parseFloat(
      this.element.dataset.priceCalculatorCommissionRate
    )

    const commission = publicPrice * commissionRate
    const revenue = publicPrice - commission

    this.priceTextTargets.forEach(target => {
      target.innerText = rounded(price)
    })
    this.commissionTarget.innerText = rounded(commission)
    this.revenueTarget.innerText = rounded(revenue)
  }

  _maxPromotionPercentage() {
    const minimum = parseFloat(this.element.dataset.priceCalculatorMinimum)
    const price = this._price()

    for (let percent = 100; percent > 0; percent--) {
      if (percentage(price, percent) >= minimum) return percent
    }

    return 0
  }

  _price() {
    return parseFloat(this.priceTarget.value)
  }

  _publicPrice() {
    const price = this._price()
    if (!this.hasPromotionPercentageTarget) return price

    return percentage(price, this.promotionPercentageTarget.value)
  }
}
