import { Controller } from "stimulus"
import { useHotkeys, useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = ["panel"]

  connect() {
    useClickOutside(this, {
      dispatchEvent: false,
      onlyVisible: false,
    })
    useHotkeys(this, {
      esc: [this.clickOutside],
    })
  }

  clickOutside() {
    this.panelTarget.setAttribute("hidden", "true")
  }

  togglePrintlistPanel(e) {
    const panel = this.panelTarget
    const panelDisplayState = panel.getAttribute("hidden")

    if (panelDisplayState) {
      panel.removeAttribute("hidden")
    } else {
      panel.setAttribute("hidden", "true")
      e.preventDefault()
    }
  }
}
