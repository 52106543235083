import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["option"]

  close() {
    this.optionTargets.forEach(option => option.open = false)
  }

  closeOthers(e) {
    const ignoredDetails = this._parentsMatchingSelector(e.target, "details")

    this.optionTargets.forEach((option) => {
      if (ignoredDetails.indexOf(option) == -1) {
        option.open = false
      }
    })
  }

  _parentsMatchingSelector(element, selector) {
    const parents = [];
    while (element = element.closest(selector)) {
      parents.push(element);
      element = element.parentNode;
    }
    return parents;
  }
}
