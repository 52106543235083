import { Controller } from "stimulus";
import { show } from "../helpers/hide";

export default class extends Controller {
  static targets = [
    "kind",
    "companyName",
    "countryCode",
    "vin",
    "tin",
  ]

  update() {
    // Updates here should also be applied to the component as hidden defaults
    show(this.companyNameTarget, this._isCompany())
    show(this.vinTarget, this._isCompany() && this._isEuropeanNeighbor())
    show(this.tinTarget, this._isCompany())
  }

  _isCompany() {
    return this.kindTarget.value == "company"
  }

  _isEuropeanNeighbor() {
    const country = this.countryCodeTarget.selectedOptions[0]
    return country?.dataset?.legalEntityEuropeanNeighbor == "true"
  }
}
