import { Controller } from "stimulus";
import { jsonFetch } from "helpers/json_fetch";

export default class extends Controller {
  static targets = ["button"]

  connect() {
    this._fetchFollowedNicks().then(({ nicks }) => {
      this.buttonTargets.forEach(button => {
        if (!nicks.includes(button.dataset.followBatchNick)) return

        button.dispatchEvent(new CustomEvent("cults:markAsFollowed"))
      })
    })
  }

  _fetchFollowedNicks() {
    return jsonFetch("/en/followees", { method: "GET" })
  }
}
