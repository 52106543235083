import { Controller } from "stimulus";
import { jsonFetch } from "helpers/json_fetch"
import { formatFloat, formatInt } from "helpers/format";

export default class extends Controller {
  static targets = [
    "star",
    "form",
    "userRating",
    "userRatingValue",
    "averageValue",
    "countValue",
    "loader",
  ]

  init(e) {
    this.data.set("userValue", e.detail.rating);
    this._update();
  }

  enter(e) {
    this.data.set("currentValue", parseFloat(e.target.value));
    this._update()
  }

  leave() {
    this.data.delete("currentValue");
    this._update();
  }

  choose(e) {
    e.preventDefault()

    this._fetch({
      method: "POST",
      body: {
        value: parseFloat(e.target.closest(".rate__star").value),
      },
    });
  }

  delete(e) {
    e.preventDefault();

    // Prevents the page from following the delete button and scrolling up when
    // it is removed.
    e.target.blur();

    this._fetch({ method: "DELETE" })
  }

  _fetch(options) {
    // Consider that we are loading if request takes more than 300ms
    // Only handles one request at a time, since there is only one loading state
    const loadingTimeout = setTimeout(() => {
      this.data.set("loading", true)
      this._update()
    }, 300);

    jsonFetch(this.formTarget.action, options)
      .catch(this._handleError)
      .then(this._handleResponse)
      .then(() => {
        clearTimeout(loadingTimeout);
        this.data.delete("loading");
        this._update();
      })
  }

  _handleResponse = ({ average, count, user }) => {
    this.data.delete("loading")
    if (average) this.data.set("average", average)
    if (count) this.data.set("count", count)

    if (user) {
      this.data.set("userValue", user)
    } else {
      this.data.delete("userValue")
    }
  }

  _handleError = (response) => {
    if (response.status == 401) {
      window.location = this.data.get("authUrl")
    } else {
      console.error("Unknown error", response)
    }
    return response
  }

  _update() {
    const loading = this.data.get("loading");
    const userValue =
      this.data.get("userValue") && parseFloat(this.data.get("userValue"));
    const average = parseFloat(this.data.get("average"))
    const count = parseFloat(this.data.get("count"))
    const value = this.data.has("currentValue")
      ? this.data.get("currentValue")
      : average;

    this.loaderTarget.classList.toggle("is-hidden", !loading)

    // user value
    this.element.classList.toggle("rate--done", !loading && userValue);
    this.userRatingTargets.forEach(target => {
      target.classList.toggle("is-hidden", loading || !userValue)
    });
    this.userRatingValueTargets.forEach(target => {
      target.innerText = formatFloat(userValue || 0);
    })

    // average
    this.averageValueTarget.innerText = formatFloat(average)
    this.countValueTarget.innerText = formatInt(count)

    this.starTargets.forEach(target => {
      target.classList.toggle("is-filled", parseFloat(target.value) <= value)
    })
  }
}
