import { Controller } from "stimulus";
import { jsonFetch } from "helpers/json_fetch";

export default class extends Controller {
  submit(e) {
    e.preventDefault();

    const url = this.data.get("url");
    const followed = this.data.get("followed") == "true";
    const method = followed ? "DELETE" : "POST";

    this._disable();
    jsonFetch(url, { method })
      .catch(this._handleError)
      .then(() => {
        this.markAsFollowed({ followed: !followed });
        this._dispatchFollowEvent({ followed: !followed });
        this._enable();
      })
  }

  markAsFollowed({ followed = true }) {
    const classes = this.element.classList;
    classes.toggle(this.data.get("followedClass"), followed);
    classes.toggle(this.data.get("unfollowedClass"), !followed);

    this.element.innerText = followed
      ? this.data.get("followedText")
      : this.data.get("unfollowedText");

    this.data.set("followed", followed ? "true" : "false");
  }

  _handleError = (response) => {
    if (response.status == 401) {
      window.location = this.data.get("authUrl")
    } else {
      console.error("Unknown error", response)
    }
    return response
  }

  _disable() {
    this.element.disabled = true;
  }

  _enable() {
    this.element.disabled = false;
  }

  _dispatchFollowEvent({ followed = true }) {
    const name = followed ? "cults:follow" : "cults:unfollow"
    window.dispatchEvent(new CustomEvent(name));
  }
}
