import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["switch"]

  open() {
    this.element.classList.add("is-open")
    this.switchTargets.forEach(target => target.open = true)
  }

  close() {
    this.element.classList.remove("is-open")
    this.switchTargets.forEach(target => target.open = false)
  }

  keypress(e) {
    if (e.key === "Enter") {
      if (this.switchTarget.open) {
        this.close()
      } else {
        this.open()
      }
    }
  }

  noop(e) {
    e.preventDefault()
  }
}
