import { Controller } from "stimulus";
import Cookies from "js-cookie";

const cookieName = "age_gate";
const className = "redacted"

export default class extends Controller {
  connect() {
    if (Cookies.get(cookieName)) this._remove();
  }

  accept() {
    Cookies.set(cookieName, true, { expires: 100 });
    this._remove();
  }

  _remove() {
    this._redactedTarget().classList.remove(className);
    this.element.remove();
  }

  _redactedTarget() {
    return document.querySelector(this.element.dataset.ageGateRedactedTarget);
  }
}
