import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["hidden"];

  show() {
    this.element.open = true
  }

  hide() {
    this.element.open = false
  }
}
