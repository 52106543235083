import { Controller } from "stimulus"
import { useHotkeys, useClickOutside } from "stimulus-use"

export default class extends Controller {
  connect() {
    useClickOutside(this, {
      dispatchEvent: false,
      onlyVisible: false,
    })

    useHotkeys(this, {
      esc: [this.clickOutside],
    })
  }

  clickOutside() {
    this.element.open = false
  }
}
