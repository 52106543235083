import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["text"];

  makeDone(e) {
    this.element.href = e.detail.make;
    this.textTarget.innerText = this.data.get("addedText");
    this.element.classList.add("btn-light");
    this.element.classList.remove("btn-third");
  }
}
