import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["all", "box"]

  checkAll(e) {
    this.boxTargets.forEach(box => box.checked = e.target.checked)
  }

  check(e) {
    this.allTarget.checked = this.boxTargets.every(box => box.checked)
  }
}
