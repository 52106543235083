const jsonFetch = async (url, { headers, body, ...options } = {}) => {
  options = {
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": csrfToken(),
      ...headers,
    }),
    credentials: "same-origin",
    body: body && JSON.stringify(body),
    ...options
  }

  const response = await fetch(url, options)

  if (!response.ok) {
    response.responseText = await response.text()
    try {
      response.responseJSON = JSON.parse(response.responseText)
    } catch (e) {
      response.responseJSON = null
    }
    return Promise.reject(response)
  }

  if (response.status == 204) return response

  return response.json()
};

const csrfToken = () => {
  return document.querySelector('meta[name="csrf-token"]').content
}

export { jsonFetch }
