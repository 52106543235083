import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"]

  reset() {
    this.inputTargets.forEach(input => {
      input.value = input.dataset.detailsResetValue
      input.dispatchEvent(new Event("change"))
    })
  }
}
