import { Controller } from "stimulus"
import { useHotkeys } from "stimulus-use"

export default class extends Controller {
  connect() {
    // Ensure theme is up to date when this element is replaced on the page.
    this._themeUpdate()

    // If the preferred browser scheme changes, update the name meta on "auto".
    this.media = window.matchMedia("(prefers-color-scheme: dark)")
    this.media.addEventListener("change", this._updateNameMetaFromBrowser)

    // Toggle scheme for development.
    useHotkeys(this, { "ctrl+t": [this._toggleTheme] })
  }

  disconnect() {
    this.media.removeEventListener("change", this._updateNameMetaFromBrowser)
  }

  // The color-scheme meta can be: "light dark", "light", "dark".
  _themeUpdate() {
    const theme = this._schemeToTheme(this.element.content)

    const themes = ["auto", "light", "dark"]
    const classes = document.documentElement.classList
    themes.forEach(t => classes.toggle(`theme-${t}`, t == theme))

    this._updateNameMetaFromBrowser()
  }

  // The theme-name meta can only be: "light", "dark". We find the name from
  // the theme-choice meta and the browser’s preferred color scheme.
  _updateNameMetaFromBrowser = () => {
    if (this.element.content != "light dark") return
    if (!window.matchMedia) return

    const media = window.matchMedia("(prefers-color-scheme: dark)")
    const meta = document.getElementById("theme-name")
    meta.content = media.matches ? "dark" : "light"
  }

  _toggleTheme() {
    const meta = document.getElementById("theme-name")
    meta.content = meta.content == "dark" ? "light" : "dark"
    this.element.content = meta.content
    this._themeUpdate()
  }

  _schemeToTheme(scheme) {
    return scheme == "light dark" ? "auto" : scheme
  }
}
