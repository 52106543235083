import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["count"]

  update(e) {
    this.data.set("count", e.data.lines_count)
    this.countTarget.innerText = this.data.get("count")

    this.element.classList.add("active")
    this.element.removeAttribute("hidden")
  }

  close() {
    this.element.classList.remove("active")

    if (this.data.get("count") === 0) {
      this.element.setAttribute("hidden", true)
    } else {
      this.element.removeAttribute("hidden")
    }
  }
}
