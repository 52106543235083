const cardStyle = color => {
  return {
    base: {
      color: "#505050",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontSize: "16px",
      "::selection": {
        color: "#fff",
        backgroundColor: color,
      },
      "::placeholder": {
        color: "#828282",
      },
      iconColor: "#828282",
    },
    invalid: {
      color: "#8b0000",
      iconColor: "#8b0000",
    }
  }
}

const idealStyle = color => {
  return {
    base: {
      padding: "6px 12px",
      borderRadius: "6px",
      border: {
        radius: "8px"
      },
      color: "#4f4f4f",
      backgroundColor: "#d3d3d3",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontSize: "16px",
      "::selection": {
        color: "#fff",
        backgroundColor: color,
      },
      '::placeholder': {
        color: "#828282",
      },
    },
  }
}

export { cardStyle, idealStyle }
