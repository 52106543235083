import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    setTimeout(this._init, 0);
  }

  _init = () => {
    const rating = this.data.get("rating");
    const make = this.data.get("make");

    if (rating) this._dispatch("init-creation-rating", { rating });
    if (make) this._dispatch("init-creation-make", { make });
  }

  _dispatch(name, detail) {
    window.dispatchEvent(new CustomEvent(name, { detail }));
  }
}
