import { Controller } from "stimulus";
import { jsonFetch } from "helpers/json_fetch"

export default class extends Controller {
  static targets = [
    "buttonText",
    "icon",
    "button",
    "number",
    "counterText",
    "counter",
  ]

  async submit(e) {
    e.preventDefault()

    const url = this.buttonTarget.dataset.likeUrl;
    const liked = this.buttonTarget.hasAttribute("data-like-liked")
    const method = liked ? "DELETE" : "POST";

    this._disable()
    await jsonFetch(url, { method })
    this.markAsLiked({ liked: !liked });
    this._updateCounter({ liked: !liked });
    this._enable();
  }

  markAsLiked({ liked = true }) {
    const classes = this.buttonTarget.classList;
    classes.toggle("btn-third", !liked);
    classes.toggle("btn-light", liked);

    const text = this.buttonTextTarget
    const data = text.dataset
    text.innerText = liked ? data.likeTextLiked : data.likeTextUnliked

    if (liked) {
      this.buttonTarget.setAttribute("data-like-liked", "")
    } else {
      this.buttonTarget.removeAttribute("data-like-liked")
    }
  }

  _updateCounter({ liked }) {
    if (liked) {
      this._update(1)
    } else {
      this._update(-1)
    }
  }

  _disable() {
    this.counterTarget.disabled = true;
    this.iconTarget.classList.remove("a-pulse-grow");
    this.iconTarget.classList.add("a--with-delay");
    this.iconTarget.classList.add("a-spin--on-with-delay");
  }

  _enable() {
    this.counterTarget.disabled = false;
    this.iconTarget.classList.remove("a--with-delay");
    this.iconTarget.classList.remove("a-spin--on-with-delay");
    this.iconTarget.classList.add("a-pulse-grow");
  }

  _update(diff) {
    const data = this.counterTarget.dataset
    const count = parseInt(data.likeCounterValue, 10) + diff;
    data.likeCounterValue = count;

    this.counterTarget.classList.toggle("hidden", count == 0)
    this.numberTarget.innerText = this._format(count);

    if (this.hasCounterTextTarget) {
      this.counterTextTarget.innerText =
        count == 1 ? data.likeCounterTextSingular : data.likeCounterTextPlural;
    }
  }

  _format(number) {
    const short = number < 1000 ? number : Math.floor(number / 100) / 10;
    return new Intl.NumberFormat(document.documentElement.lang).format(short);
  }
}
