import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "content", "prev", "next", "slide", "handle"]

  show(e) {
    this._show(e.currentTarget.dataset.index)
  }

  hide() {
    if (!this._isActive()) return

    this.containerTarget.classList.add("is-hidden")
    document.documentElement.classList.remove("is-modal-open")
    this._applyPreviousScroll()
  }

  stopPropagation(e) {
    e.stopImmediatePropagation()
  }

  keydown(e) {
    if (!this._isActive()) {
      const element = document.activeElement
      if (e.key == "Enter" && this.handleTargets.includes(element)) {
        this._show(element.dataset.index)
      }
    } else {
      if (e.key == "Escape" || e.key == "Enter") {
        this.hide()
      } else if (e.key == "ArrowLeft") {
        this.prev(e)
      } else if (e.key == "ArrowRight") {
        this.next(e)
      }
    }
  }

  scroll() {
    this._updateNav()
  }

  prev(e) {
    e.stopImmediatePropagation()
    this._triggerChange()
    this._scroll(-1)
  }

  next(e) {
    e.stopImmediatePropagation()
    this._triggerChange()
    this._scroll(+1)
  }

  _show(index) {
    this._savePreviousScroll()

    const slide = this.slideTargets.find(slide => slide.dataset.index == index)
    this._openModal()
    this._triggerChange()
    this._scrollToSlide(slide)
    this._updateNav()
  }

  _savePreviousScroll() {
    this.element.dataset.previousScrollTop = document.scrollingElement.scrollTop
  }

  _applyPreviousScroll() {
    const top = this.element.dataset.previousScrollTop
    document.scrollingElement.scroll({ top })
  }

  _updateNav() {
    const prev = this.prevTarget
    const next = this.nextTarget
    const scroll = this.contentTarget.scrollLeft
    const scrollMax = this.contentTarget.scrollWidth - content.offsetWidth
    const offset = content.offsetWidth / 2

    if (scroll <= offset) {
      prev.classList.add("is-hidden")
    } else {
      prev.classList.remove("is-hidden")
    }

    if (scrollMax <= scroll + offset) {
      next.classList.add("is-hidden")
    } else {
      next.classList.remove("is-hidden")
    }
  }

  _isActive() {
    return !this.containerTarget.classList.contains("is-hidden")
  }

  _openModal() {
    this.containerTarget.classList.remove("is-hidden")
    document.documentElement.classList.add("is-modal-open")
  }

  _triggerChange() {
    this.slideTargets.forEach(slide => {
      slide.dispatchEvent(new CustomEvent("slides:change"))
    })
  }

  _scroll(increment) {
    const content = this.contentTarget
    content.scroll({
      left: content.scrollLeft + content.offsetWidth * increment
    })
  }

  _scrollToSlide(slide) {
    this.contentTarget.classList.add("is-setting-scroll")
    slide.scrollIntoView()
    this.contentTarget.classList.remove("is-setting-scroll")
  }
}
