import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const active = this.element.querySelector(".active");
    if (active) {
      const x = active.offsetLeft - window.innerWidth / 2
      const y = active.offsetTop
      this.element.scroll(x, y)
    }
  }
}
