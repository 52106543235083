import { Controller } from "stimulus"
import Cookies from "js-cookie";

export default class extends Controller {
  dismiss() {
    const name = this.data.get("cookieName")
    Cookies.set(name, true, { expires: 365 })

    this.element.remove()
  }
}
