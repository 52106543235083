const htmlFetch = async (url, { headers, body, ...options } = {}) => {
  const token = document.querySelector('meta[name="csrf-token"]').content;

  options = {
    headers: new Headers({
      Accept: "text/html",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": token,
      ...headers,
    }),
    credentials: "same-origin",
    body: body && JSON.stringify(body),
    ...options,
  };

  const response = await fetch(url, options);
  return response.ok ? response.text() : Promise.reject(response);
};

export { htmlFetch };
