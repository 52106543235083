import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["username", "available", "unavailable", "loading"]
  static values = { url: String }

  checkAvailability() {
    const value = this.usernameTarget.value;
    const url = this.urlValue;
    this.loadingTarget.removeAttribute("hidden")
    this.unavailableTarget.setAttribute("hidden", true)
    this.availableTarget.setAttribute("hidden", true)
    fetch(`${url}?nick=${value}`)
      .then((response) => response.json())
      .then(data => {
        if (data.available) {
          this.availableTarget.removeAttribute("hidden")
          this.unavailableTarget.setAttribute("hidden", true)
        } else {
          this.unavailableTarget.removeAttribute("hidden")
          this.availableTarget.setAttribute("hidden", true)
        }
        this.loadingTarget.setAttribute("hidden", true)
      })
  }

}
