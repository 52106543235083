import { Controller } from "stimulus"
import { htmlFetch } from "helpers/html_fetch"

export default class extends Controller {
  static targets = ["form", "select", "content", "loader"]

  async update() {
    this._loadStart()

    const url = new URL(this.formTarget.action)
    url.searchParams.set(this.selectTarget.name, this.selectTarget.value)
    this.contentTarget.innerHTML = await htmlFetch(url)

    this._loadEnd()
  }

  _loadStart() {
    this.selectTarget.disabled = true

    this._loadingTimeout = setTimeout(() => {
      this.loaderTarget.classList.remove("is-hidden")
    }, 300)
  }

  _loadEnd() {
    clearTimeout(this._loadingTimeout)
    this.loaderTarget.classList.add("is-hidden")

    this.selectTarget.disabled = false
  }
}
