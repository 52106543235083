import { Controller } from "stimulus"
import { injectScript } from "helpers/inject_script"
import { jsonFetch } from "helpers/json_fetch"

export default class extends Controller {
  static targets = [
    "paypalError",
    "loading",
    "paymentError",
    "buttonContainer",
  ]

  connect() {
    injectScript(this._sdkUrl())
      .then(this._initPaypal)
      .catch(this._paypalError)
  }

  _sdkUrl() {
    const url = new URL("https://www.paypal.com/sdk/js")
    url.searchParams.append("client-id", this.data.get("clientId"))
    url.searchParams.append("locale", this.data.get("locale"))
    url.searchParams.append("currency", this.data.get("currency"))
    return url
  }

  _initPaypal = () => {
    const button = paypal.Buttons({
      fundingSource: paypal.FUNDING.PAYPAL,
      style: {
        height: 42, // 25..55
        color: "blue",
      },
      createOrder: this._createPaypalOrder,
      onApprove: this._onApprove,
    })

    button
      .render(this.buttonContainerTarget)
      .then(() => this.loadingTarget.hidden = true)
  }

  // https://developer.paypal.com/docs/business/checkout/server-side-api-calls/create-order/
  _createPaypalOrder = (_data, _actions) => {
    this._loading()
    this.paymentErrorTarget.hidden = true
    this.paypalErrorTarget.hidden = true

    return jsonFetch(this.data.get("intentsUrl"), { method: "POST" })
      .then(result => {
        this._loaded()
        return result.paypal_order.code
      })
      .catch(this._paymentError)
  }

  // https://developer.paypal.com/docs/business/checkout/server-side-api-calls/capture-order/
  _onApprove = (data, _actions) => {
    this._loading()
    const body = { paypal_order_code: data.orderID }
    return jsonFetch(this.data.get("capturesUrl"), { method: "POST", body })
      .then(data => {
        if (data.state == "successful") {
          // This clears the cart
          window.location = data.orderPath
        } else {
          throw new Error(`State was “${data.state}”`)
        }
      })
      .catch(this._paymentError)
  }

  _loading = () => {
    document.dispatchEvent(new CustomEvent("cults:loading"))
    this.loadingTarget.hidden = false
  }

  _loaded = () => {
    document.dispatchEvent(new CustomEvent("cults:loaded"))
    this.loadingTarget.hidden = true
  }

  _paypalError = (error) => {
    console.error("PayPal error:", error)
    this.paypalErrorTarget.hidden = false
    this._loaded()
  }

  _paymentError = (error) => {
    console.error("Payment error:", error)
    this.paymentErrorTarget.hidden = false
    this._loaded()
  }
}
