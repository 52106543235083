import { Controller } from "stimulus"

export default class extends Controller {
  change() {
    const value = this.element.value
    let href = null

    this.element.options.forEach((option) => {
      if (option.value == value) href = option.dataset.selectLocationHref
    })

    if (href) Turbo.visit(href)
  }
}
