import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["observed", "appearing"]

  connect() {
    this.observer = new IntersectionObserver(this._intersection, {
      threshold: 0.2
    })
    this.observer.observe(this.observedTarget)
  }

  disconnect() {
    this.observer.disconnect()
  }

  _intersection = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.appearingTarget.hidden = true
      } else {
        this.appearingTarget.removeAttribute("hidden")
      }
    })
  }
}
