import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["number", "text"]

  increment() {
    this._update(1)
  }

  decrement() {
    this._update(-1)
  }

  _update(diff) {
    const data = this.data
    const count = parseInt(data.get("value"), 10) + diff;
    data.set("value", count)

    this.element.classList.toggle("hidden", count == 0)
    this.numberTarget.innerText = this._format(count);

    if (this.hasTextTarget) {
      this.textTarget.innerText =
        count == 1 ? data.get("textSingular") : data.get("textPlural");
    }
  }

  _format(number) {
    const short = number < 1000 ? number : Math.floor(number / 100) / 10;
    return new Intl.NumberFormat(document.documentElement.lang).format(short);
  }
}
