import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submit", "input"]

  connect() {
    this.toggleValidationSubmission()
  }

  submit() {
    this.element.requestSubmit()
  }

  dismiss() {
    this.element.classList.add("form--dismissed")
  }

  toggleValidationSubmission() {
    const valid = this.inputTargets.every(input => !input.validity.valueMissing)
    this.submitTargets.forEach(submit => submit.disabled = !valid)
  }
}
