import { Controller } from "stimulus"

// Time ago in words that stays up to date. Follows the Rails time scale.
//
// Example:
//
//   <time data-controller="time" datetime="2020-05-18T19:22:17Z">
//     about 1 hour ago
//   </time>

const TEXTS = {
  en: [
    [0, _ => "less than a minute ago"],
    [1, _ => "a minute ago"],
    [2, m => `${m.toFixed()} minutes ago`],
    [45, _ => "about 1 hour ago"],
    [90, m => `about ${(m / 60).toFixed()} hours ago`],
    [1440, _ => "1 day ago"],
    [2880, m => `${(m / 1440).toFixed()} days ago`],
    [43200, _ => "about 1 month ago"],
    [86400, m => `${(m / 43200).toFixed()} months ago`],
    [525960, _ => "about 1 year ago"],
    [1051200, m => `${(m / 525960).toFixed()} years ago`],
    [Number.MAX_VALUE],
  ],
  fr: [
    [0, _ => "il y a moins d’une minute"],
    [1, _ => "il y a une minute"],
    [2, m => `il y a ${m.toFixed()} minutes`],
    [45, _ => "il y a environ 1 heure"],
    [90, m => `il y a environ ${(m / 60).toFixed()} heures`],
    [1440, _ => "il y a 1 jour"],
    [2880, m => `il y a ${(m / 1440).toFixed()} jours`],
    [43200, _ => "il y a environ 1 mois"],
    [86400, m => `il y a ${(m / 43200).toFixed()} mois`],
    [525960, _ => "il y a environ 1 an"],
    [1051200, m => `il y a ${(m / 525960).toFixed()} ans`],
    [Number.MAX_VALUE],
  ],
  es: [
    [0, _ => "hace menos de 1 minuto"],
    [1, _ => "hace 1 minuto"],
    [2, m => `hace ${m.toFixed()} minutos`],
    [45, _ => "hace alrededor de 1 hora"],
    [90, m => `hace alrededor de ${(m / 60).toFixed()} horas`],
    [1440, _ => "hace 1 día"],
    [2880, m => `hace ${(m / 1440).toFixed()} días`],
    [43200, _ => "hace 1 mes"],
    [86400, m => `hace ${(m / 43200).toFixed()} meses`],
    [525960, _ => "hace 1 año"],
    [1051200, m => `hace ${(m / 525960).toFixed()} años`],
    [Number.MAX_VALUE],
  ],
  de: [
    [0, _ => "vor weniger als eine Minute"],
    [1, _ => "vor eine Minute"],
    [2, m => `vor ${m.toFixed()} Minuten`],
    [45, _ => "vor etwa eine Stunde"],
    [90, m => `vor etwa ${(m / 60).toFixed()} Stunden`],
    [1440, _ => "vor ein Tag"],
    [2880, m => `vor ${(m / 1440).toFixed()} Tage`],
    [43200, _ => "vor etwa ein Monat"],
    [86400, m => `vor etwa ${(m / 43200).toFixed()} Monate`],
    [525960, _ => "vor etwa ein Jahr"],
    [1051200, m => `vor etwa ${(m / 525960).toFixed()} Jahre`],
    [Number.MAX_VALUE],
  ],
}

const UPDATE_SECONDS = 30

export default class extends Controller {
  connect() {
    // Listen for changes in the `lang` attribute.
    this.htmlObserver = new MutationObserver(this._updateOnHtmlLangChange)
    this.htmlObserver.observe(document.documentElement, { attributes: true })

    // Update regulary
    this.update()
    setInterval(this.update, UPDATE_SECONDS * 1000)
  }

  disconnect() {
    this.htmlObserver.disconnect()
  }

  update = () => {
    const time = Date.parse(this.element.attributes.datetime.value)
    this.element.innerText = this._timeAgoInWords(time, Date.now())
  }

  _updateOnHtmlLangChange = (mutationList) => {
    mutationList.forEach(mutation => {
      if (mutation.attributeName == "lang") this.update()
    })
  }

  // Via https://gist.github.com/boxnos/5896114
  _timeAgoInWords(from, to) {
    const texts = TEXTS[document.documentElement.lang] || TEXTS.en
    const diff = to < from ? 0 : to - from
    const minutes = diff / (60 * 1000)

    const result = this._bSearch(
      minutes,
      0,
      Math.floor((texts.length - 1) / 2),
      texts.length - 1,
      texts
    )

    return result[1](minutes)
  }

  _bSearch(value, lower, pos, upper, texts) {
    if (texts[pos][0] <= value && value < texts[pos + 1][0]) {
      return texts[pos]
    } else if (value < texts[pos][0]) {
      return this._bSearch(
        value,
        lower,
        Math.floor((lower + pos - 1) / 2),
        pos - 1,
        texts
      )
    } else {
      return this._bSearch(
        value,
        pos + 1,
        Math.floor((pos + 1 + upper) / 2),
        upper,
        texts
      )
    }
  }
}
