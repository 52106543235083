import { Controller } from "stimulus"
import { jsonFetch } from "helpers/json_fetch"
import { injectScript } from "helpers/inject_script"
import { cardStyle, idealStyle } from "helpers/stripe"

export default class extends Controller {
  static targets = [
    "error",
    "stripeError",
    "verificationError",
    "verificationErrorMessage",
    "transactionId",
    "loading",

    "card",
    "cardSubmit",
    "cardSpinner",
    "cardRemember",
    "cardError",

    "previousCardContainer",
    "previousCardSubmit",
    "previousCardError",

    "oneClick",
    "oneClickAppleLogo",
    "oneClickOtherLogo",
    "oneClickError",
    "oneClickFailed",

    "bancontactName",
    "bancontactSubmit",
    "bancontactError",

    "idealName",
    "idealElement",
    "idealSubmit",
    "idealError",

    "giropayName",
    "giropaySubmit",
    "giropayError",

    "epsName",
    "epsSubmit",
    "epsError",

    "p24Submit",
    "p24Error",

    "alipaySubmit",
    "alipayError",
  ]

  connect() {
    injectScript("https://js.stripe.com/v3/")
      .then(this._initStripe)
      .catch(() => this.stripeErrorTarget.classList.remove("is-hidden"))
  }

  payOneClick(e) {
    e.preventDefault()
    e.stopPropagation()

    this.paymentRequest.show()
  }

  payCard(e) {
    e.preventDefault()
    e.stopPropagation()

    this.cardSubmitTarget.disabled = true
    this.cardErrorTarget.textContent = null

    const rememberOptions =
      this.cardRememberTarget.checked ?
        { setup_future_usage: "on_session" } :
        {}

    this
      ._createPaymentIntent("card")
      .then(() => this.stripe.confirmCardPayment(this.clientSecret, {
        payment_method: { card: this.card },
        ...rememberOptions
      }))
      .then(this._rejectStripeError)
      .then(this._checkPaymentIntent)
      .catch(error => {
        this.cardSubmitTarget.disabled = false
        this.cardErrorTarget.textContent = error
      })
  }

  payPreviousCard(e) {
    e.preventDefault()
    e.stopPropagation()

    this.previousCardSubmitTarget.disabled = true
    this.previousCardErrorTarget.textContent = null

    this
      ._createPaymentIntent("previous_card")
      .then(() => this.stripe.confirmCardPayment(this.clientSecret, {
        payment_method:
          this.previousCardSubmitTarget.dataset.stripePaymentPreviousCardId,
      }))
      .then(this._rejectStripeError)
      .then(this._checkPaymentIntent)
      .catch(error => {
        this.previousCardSubmitTarget.disabled = false
        this.previousCardErrorTarget.textContent = error
      })
  }

  payBancontact(e) {
    e.preventDefault()
    e.stopPropagation()

    this.bancontactSubmitTarget.disabled = true
    this.bancontactErrorTarget.innerText = null
    this
      ._createPaymentIntent("bancontact")
      .then(() => this.stripe.confirmBancontactPayment(this.clientSecret, {
        payment_method: {
          billing_details: {
            name: this.bancontactNameTarget.value,
          },
        },
        return_url: this._intentUrl(),
      }))
      .then(this._rejectStripeError)
      .catch(error => {
        this.bancontactSubmitTarget.disabled = false
        this.bancontactErrorTarget.innerText = error
      })
  }

  payIdeal(e) {
    e.preventDefault()
    e.stopPropagation()

    this.idealSubmitTarget.disabled = true
    this.idealErrorTarget.innerText = null
    this
      ._createPaymentIntent("ideal")
       .then(() => this.stripe.confirmIdealPayment(this.clientSecret, {
        payment_method: {
          ideal: this.idealBank,
          billing_details: {
            name: this.idealNameTarget.value,
          },
        },
        return_url: this._intentUrl(),
      }))
      .then(this._rejectStripeError)
      .catch(error => {
        this.idealSubmitTarget.disabled = false
        this.idealErrorTarget.innerText = error
      })
  }


  payGiropay(e) {
    e.preventDefault()
    e.stopPropagation()

    this.giropaySubmitTarget.disabled = true
    this.giropayErrorTarget.innerText = null
    this
      ._createPaymentIntent("giropay")
      .then(() => this.stripe.confirmGiropayPayment(this.clientSecret, {
        payment_method: {
          billing_details: {
            name: this.giropayNameTarget.value,
          },
        },
        return_url: this._intentUrl(),
      }))
      .then(this._rejectStripeError)
      .catch(error => {
        this.giropaySubmitTarget.disabled = false
        this.giropayErrorTarget.innerText = error
      })
  }

  payEps(e) {
    e.preventDefault()
    e.stopPropagation()

    this.epsSubmitTarget.disabled = true
    this.epsErrorTarget.innerText = null
    this
      ._createPaymentIntent("eps")
      .then(() => this.stripe.confirmEpsPayment(this.clientSecret, {
        payment_method: {
          billing_details: {
            name: this.epsNameTarget.value,
          },
        },
        return_url: this._intentUrl(),
      }))
      .then(this._rejectStripeError)
      .catch(error => {
        this.epsSubmitTarget.disabled = false
        this.epsErrorTarget.innerText = error
      })
  }

  payP24(e) {
    e.preventDefault()
    e.stopPropagation()

    this.p24SubmitTarget.disabled = true
    this.p24ErrorTarget.innerText = null
    this
      ._createPaymentIntent("p24")
      .then(() => this.stripe.confirmP24Payment(this.clientSecret, {
        payment_method: {
          billing_details: {
            email: this.data.get("email"),
          },
        },
        return_url: this._intentUrl(),
      }))
      .then(this._rejectStripeError)
      .catch(error => {
        this.p24SubmitTarget.disabled = false
        this.p24ErrorTarget.innerText = error
      })
  }

  payAlipay(e) {
    e.preventDefault()
    e.stopPropagation()

    this.alipaySubmitTarget.disabled = true
    this.alipayErrorTarget.innerText = null
    this
      ._createPaymentIntent("alipay")
      .then(() => this.stripe.confirmAlipayPayment(this.clientSecret, {
        return_url: this._intentUrl(),
      }))
      .then(this._rejectStripeError)
      .catch(error => {
        this.alipaySubmitTarget.disabled = false
        this.alipayErrorTarget.innerText = error
      })
  }

  mountCard() {
    if (this.card) this.card.destroy()

    this.cardErrorTarget.textContent = null

    this.card = this.elements.create("card", {
      style: cardStyle(this.data.get("color"))
    })
    this.card.mount(this.cardTarget)
    this.card.on("change", ({ error }) => {
      this.cardErrorTarget.textContent = error ? error.message : null
      this.cardSubmitTarget.disabled = !!error
      this.cardSpinnerTarget.classList.toggle("is-hidden", !!error)
    })
  }

  mountPreviousCard() {
    this.previousCardErrorTarget.textContent = null
  }

  deletePreviousCard(e) {
    e.preventDefault()
    e.stopPropagation()

    const button = e.target

    if (!confirm(button.dataset.confirmText)) return

    button.disabled = true

    jsonFetch(button.dataset.deleteUrl, { method: "DELETE" })
      .then(() => {
        this.previousCardContainerTarget.classList.add("is-hidden")
      })
      .catch(error => {
        this.previousCardErrorTarget.innerText = error.statusText
      })
  }

  _initStripe = () => {
    this.stripe = Stripe(this.data.get("apiKey"))
    this.elements = this.stripe.elements()

    this.cardSubmitTarget.disabled = false
    this.loadingTarget.classList.add("is-hidden")

    this._mountOneClick()
    this._mountBancontact()
    this._mountIdeal()
    this._mountGiropay()
    this._mountEps()
    this._mountP24()
    this._mountAlipay()
  }

  _createPaymentIntent = (method) => {
    const body = { payment_method: `stripe_${method}` }
    return jsonFetch(this.data.get("intentsUrl"), { method: "POST", body })
      .then(({ clientSecret, transactionId }) => {
        this.clientSecret = clientSecret
        this.transactionId = transactionId
      })
      .catch(e => Promise.reject(e.statusText))
  }

  _mountOneClick() {
    this.paymentRequest = this.stripe.paymentRequest({
      country: "FR",
      currency: this.data.get("currency"),
      total: {
        label: this.data.get("label"),
        amount: parseInt(this.data.get("amountCents")),
      },
      displayItems: JSON.parse(this.data.get("items")),
      requestPayerName: true,
    })
    this.paymentRequest.canMakePayment().then((result) => {
      if (!result) return

      const apple = result.applePay

      // Disable Apple Pay while there are issues with the event handling.
      if (apple && !this.hasOneClickAppleLogoTarget) return

      this
        ._createPaymentIntent("one_click")
        .then(() => {
          this.oneClickTarget.classList.remove("is-hidden")
          this.oneClickAppleLogoTarget.classList.toggle("is-hidden", !apple)
          this.oneClickOtherLogoTarget.classList.toggle("is-hidden", apple)
        })
    })
    this.paymentRequest.on("paymentmethod", this._confirmOneClick)
  }

  _mountBancontact() {
    if (!this.hasBancontactSubmitTarget) return

    this.bancontactSubmitTarget.disabled = false
  }

  _mountIdeal() {
    if (!this.hasIdealSubmitTarget) return

    this.idealBank = this.elements.create("idealBank", {
      style: idealStyle(this.data.get("color")),
    })
    this.idealBank.mount(this.idealElementTarget)

    this.idealSubmitTarget.disabled = false
  }

  _mountGiropay() {
    if (!this.hasGiropaySubmitTarget) return

    this.giropaySubmitTarget.disabled = false
  }

  _mountEps() {
    if (!this.hasEpsSubmitTarget) return

    this.epsSubmitTarget.disabled = false
  }

  _mountP24() {
    if (!this.hasP24SubmitTarget) return

    this.p24SubmitTarget.disabled = false
  }

  _mountAlipay() {
    if (!this.hasAlipaySubmitTarget) return

    this.alipaySubmitTarget.disabled = false
  }

  // https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-complete-payment
  _confirmOneClick = (e) => {
    this.cardSubmitTarget.disabled = false
    this.oneClickFailedTarget.classList.add("is-hidden")
    this.oneClickErrorTarget.classList.add("is-hidden")

    this.stripe.confirmCardPayment(
      this.clientSecret,
      { payment_method: e.paymentMethod.id },
      { handleActions: false }
    ).then(({ error, paymentIntent }) => {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      if (error) {
        e.complete("fail")
        this.oneClickFailedTarget.classList.remove("is-hidden")
        return
      }

      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      e.complete("success")

      // Check if the PaymentIntent requires any other actions. If so let
      // Stripe.js handle the flow, else, we are already successful.
      if (paymentIntent.status != "requires_action") {
        this._checkPaymentIntent()
        return
      }

      this.stripe.confirmCardPayment(this.clientSecret).then(({ error }) => {
        if (error) {
          this.oneClickFailedTarget.classList.remove("is-hidden")
        } else {
          this._checkPaymentIntent()
        }
      })
    }).catch(() => {
      this.oneClickErrorTarget.classList.remove("is-hidden")
    })
  }

  // UI helpers

  _checkPaymentIntent = () => {
    if (!this.verificationAttempts) this.verificationAttempts = 0

    this.verificationErrorTarget.classList.add("is-hidden")

    jsonFetch(this._intentUrl())
      .then(data => {
        if (data.state == "successful") {
          // This clears the cart
          window.location = data.orderPath
        } else if (this.verificationAttempts >= 30) {
          this._verificationError(
            `${this.verificationAttempts} checks, state ${data.state}`
          )
        } else {
          this.verificationAttempts += 1
          setTimeout(this._checkPaymentIntent, this.verificationAttempts * 150)
        }
      })
      .catch(this._verificationError)
  }

  _intentUrl() {
    return `${this.data.get("intentsUrl")}/${this.transactionId}`
  }

  _verificationError = (message) => {
    this.verificationErrorTarget.classList.remove("is-hidden")
    this.transactionIdTarget.innerText = this.transactionId
    this.verificationErrorMessageTarget.innerText = message
  }

  _rejectStripeError(result) {
    if (result.error) return Promise.reject(result.error.message)
  }
}
