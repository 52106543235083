import { Controller } from "stimulus"
import { useHotkeys } from "stimulus-use"

export default class extends Controller {
  connect() {
    useHotkeys(this, {
      "ctrl+g": [this.toggle],
      esc: [this.hide],
    })
  }

  // private

  toggle() {
    if (this.element.getAttribute("aria-hidden") == "true") {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    this.element.setAttribute("aria-hidden", "false")

    document.querySelectorAll("[id^=zone-]").forEach(element => {
      element.classList.add("dev-debug")
    })
  }

  hide() {
    this.element.setAttribute("aria-hidden", "true")

    document.querySelectorAll("[id^=zone-]").forEach(element => {
      element.classList.remove("dev-debug")
    })
  }
}
