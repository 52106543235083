const formatFloat = (float) => {
  const lang = document.documentElement.lang
  const string = float.toLocaleString(lang)

  // Strip insignificant zero
  return string.replace(/[.,]0$/, "")
}

const formatInt = (float) => {
  const lang = document.documentElement.lang;
  return float.toLocaleString(lang);
};

export { formatFloat, formatInt };
