import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const event = new Event("cart-alert:update")
    event.data = JSON.parse(this.data.get("data"))
    document.dispatchEvent(event)

    window.scroll(this.element.offsetTop, 0)

    this.element.focus()
  }

  close() {
    this.element.remove()
    document.dispatchEvent(new Event("cart-alert:close"))
  }
}
