import { Controller } from "stimulus";
import { useHotkeys } from "stimulus-use"

// Add a quick hotkey shortcut to a link.
//
// Example:
//   <a
//     data-controller="hotkey-link"
//     data-hotkey-link-key-value="ctrl+a"
//     title="Admin"
//     href="/admin"
//   >
//     Admin
//   </a>
export default class extends Controller {
  static values = { key: String }

  connect() {
    useHotkeys(this, { [this.keyValue]: [this.click] })

    this.element.title = this._title()
  }

  click() {
    this.element.dispatchEvent(new MouseEvent("click"))
  }

  _title() {
    return [this.element.title, `(${this.keyValue})`].filter(v => v).join(" ")
  }
}
